import './scrollingtechstack.css'
export default function ScrollingTechStack() {

    let techStackArray = ['html', 'javascript', 'react_black', 'css', 'node', 'express', 'next', 'postgresql', 'jest', 'figma', 'github', 'supabase', 'three', 'typescript', 'npm']

    let techStackArrayMapped = techStackArray.map((tech, index) => {
        return (
            <div className="tech-stack-item" key={index}>
                <img className='tech-logo'src={`./${tech}.svg`} alt={`${tech} logo`}/>
            </div>
        )
    });

    // the two segments are both 100vw wide, so the total width is 200vw
    // the animation moves the first segment to the left by 100vw
    return (
        <div id="scrolling-tech-stack">
            <div className='animate'>
                <div className='tech-stack-segment'>
                    {techStackArrayMapped}
 
                </div> 
                <div className='tech-stack-segment'>
                    {techStackArrayMapped}
                    
                </div>
                <div className='tech-stack-segment'>
                    {techStackArrayMapped}
                    
                </div>
                <div className='tech-stack-segment'>
                    {techStackArrayMapped}
                    
                </div>
                
            </div>
        </div>
    )
}
