import './landingpage.css'
import AboutMe from '../../Components/AboutMe'
import ProjectsGrid from '../../Components/ProjectsGrid'
import AboutMeSection from '../../Components/AboutMeSection'

export default function LandingPage() {
    return (<div id="landing-page-container">
        <div className="centered-content">
            <AboutMe/>
            <h1>Recent Work</h1> 
            <ProjectsGrid/>
            {/* <AboutMeSection/> */}
            <div id="contact">
                <h2>Contact</h2>
                <p>Feel free to get in touch with me here:</p>
                <div id="contact-icons">
                <a href='https://www.linkedin.com/in/isaac-boxall-310788138/' target="_blank" rel="noreferrer"><img src='./linkedin.svg' alt='linkedin'/></a>
                <a href='mailto:isaacboxall1@gmail.com'><img src='./email.svg' alt='email' target="_blank" rel="noreferrer"/></a>
                <a href='https://github.com/isaacboxall1' target="_blank" rel="noreferrer"><img src='./github_white.svg' alt='github'/></a>
                </div>
            </div>
        </div>
    </div>
    
    )}
    