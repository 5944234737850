import { BrowserRouter, Routes, Route } from "react-router-dom";
import {useState} from 'react'
import Header from './Components/Header'
import LandingPage from './pages/LandingPage'
import ContactPage from "./pages/ContactPage";
import ProjectsPage from "./pages/ProjectsPage";

function App() {


  return (
   
    <BrowserRouter>
        {/* <Header/> */}
        <Routes>
          <Route path="/"> 
            <Route index element={<LandingPage/>}/>
            <Route path="contact" element={<ContactPage/>}/>
            <Route path="projects" element={<ProjectsPage/>}/>
          </Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
