import './aboutme.css'
import ScrollingTechStack from '../ScrollingTechStack'
export default function AboutMe() {

    return (
        <div id="back-drop-container">
            <div id="about-me-container">
                 <img id="circle-profile-image" src='./profile-image.webp' alt='Portrait of Isaac Boxall'/>
                <div id="name-and-title">
                    <h1>Isaac Boxall</h1>
                    <h3>Junior Full Stack Developer</h3>
                </div>
                <ScrollingTechStack/>
            </div>
        </div>
    )
}
