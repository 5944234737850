export const projects = [{
    title: 'Walk Mate',
    description: 'A walking route and event planner, built as part of a team of 6 across 5 weeks as part of the School of Code bootcamp.',
    image: './walkmate.png',
    github: 'https://github.com/CodeyCepsSOC/bc14_CodeyCeps_FinalProject',
    live: 'https://walkmate.netlify.app/',
    techStack: ['react', 'css', 'jest', 'supabase']
},
{
    title: 'Three.js Watch Editor',
    description: 'A simple Web Application built using React Three Fiber, that allows editing of the color of buttons, strap and body of a watch, displayed in 3D.',
    image: './watcheditor.png',
    github: 'https://github.com/Isaacboxall1/Watch-Editor',
    live: 'https://watch-editor.netlify.app',
    techStack: ['three', 'react', 'css',]
},
{
    title: 'Chat GPT Weather App',
    description: 'A Weather App built in Typescript that utilises the Open AI API to suggest what to do based on the forecast of your chosen area.',
    image: './weatherapp.png',
    github: 'https://github.com/Isaacboxall1/Weather-GPT',
    live: 'https://weathergpt.netlify.app',
    techStack: ['typescript','chatgpt', 'react', 'css']
},






]