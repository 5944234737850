import './projectcard.css'
export default function ProjectCard({project}) { 
    
    return (
        <div className="project-card">
            <div className="project-card-image-container">
                <img className="project-card-image" src={project.image} alt={project.title}/>
            </div>

            <div className="project-card-text-container">
                <h3>{project.title}</h3>
                <p>{project.description}</p>
            </div>
            <div id="project-tech-stack">
               {project.techStack.map((tech, index) => { return <img className='tech-logo'src={`./${tech}.svg`} alt={`${tech} logo`}/>})} 
               
            </div>
            <div className="project-card-links">
                    <a href={project.github} target="_blank" rel="noreferrer">Github <img src='./github.svg' alt='github link' id="github-logo"/></a>
                    <a href={project.live} target="_blank" rel="noreferrer">View Deployed Site</a>
            </div>

        </div>
)}